import { render, staticRenderFns } from "./MarketingSegmentsForm.vue?vue&type=template&id=9d786fe8&scoped=true"
import script from "./MarketingSegmentsForm.vue?vue&type=script&lang=js"
export * from "./MarketingSegmentsForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d786fe8",
  null
  
)

export default component.exports